



















  import { Validator } from '@/constant/Mixins';
  import { ServerResponse } from '@/services/response.types';
  import Component from 'vue-class-component';
  import { Mixins, Prop } from 'vue-property-decorator';
  import { Action } from 'vuex-class';

  interface CallMeParamsInterface {
    name: string;
    email: string;
    phone: string;
    encrypted: string;
  }

  @Component({
    name: 'FormCallMe',
    components: {},
    mixins: [],
  })
  export default class FormCallMe extends Mixins(Validator) {
    @Action('CallMe') CallMe!: (form: FormData) => Promise<ServerResponse>;

    @Prop({ type: String, required: true, default: null }) quotation_id: string;

    public onSubmit = false;
    public formCallMe: CallMeParamsInterface = {
      name: '',
      email: '',
      phone: '',
      encrypted: '',
    };

    public created(): void {
      // on componenet created
    }
    public mounted(): void {
      // on componenet mounted
    }

    /**
     * OnSubmit
     */
    public OnSubmit(ev: Event) {
      this.onSubmit = true;
      this.CallMe(new FormData(ev.target as HTMLFormElement))
        .then((res) => this.$emit('success', res.status.is_valid))
        .finally(() => (this.onSubmit = false));
    }
  }
